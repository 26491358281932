<div
  type="button"
  class="cart-product-card"
  [class.cart-product-card--related]="useRelatedStyles"
  [class.cart-product-card--sidebar]="useSidebarStyles"
  (click)="toggleToCart($event)"
  [class.selected]="selected"
>
  <div class="cart-product-card__header">
    <div
      *ngLet="isProductSelected$ | async as selected"
      class="product-icon"
      [class.selected]="selected"
    >
      @if (product.logo) {
      <img
        [alt]="product.logo"
        [src]="product.logo"
      />
      }
    </div>

    <ng-template *ngTemplateOutlet="actions" />
  </div>

  <div class="cart-product-card__body">
    <div class="title">{{ product.name }}</div>
  </div>
</div>

<ng-template #actions>
  @switch (actionType) { @case ('add') {
  <div class="btn-add-to-cart">
    <svg-icon
      name="icons/icon-plus-new.svg#icon-plus-new"
      [size]="24"
    />
  </div>
  } @case ('remove') {
  <div class="btn-remove-from-cart">
    <assets-icon
      icon="delete"
      [size]="22"
    />
  </div>
  } @case ('checked') {
  <ng-container *ngLet="isProductSelected$ | async as selected">
    <button [class.btn-add-to-cart]="!selected">
      @if (selected) {
      <assets-icon
        icon="check"
        [size]="24"
      />
      } @else {
      <svg-icon
        name="icons/icon-plus-new.svg#icon-plus-new"
        [size]="24"
      />
      }
    </button>
  </ng-container>
  } }
</ng-template>
