export enum EnCAEvents {
  // default
  USER_LANDED = 'USER_LANDED',
  USER_LEFT = 'USER_LEFT',

  // origin
  LANGUAGE_CHANGED = 'LANGUAGE_CHANGED',

  // marketplace
  CATALOG_ADD_PRODUCT = 'CATALOG_ADD_PRODUCT',
  CATALOG_REMOVE_PRODUCT = 'CATALOG_REMOVE_PRODUCT',
  CATALOG_PRODUCT_READ_MORE = 'CATALOG_PRODUCT_READ_MORE',
  CATALOG_APPLY_FILTER = 'CATALOG_APPLY_FILTER',
  CATALOG_CLICK_CATEGORY = 'CATALOG_CLICK_CATEGORY',

  CART_ADD_RECOMMENDED_PRODUCT = 'CART_ADD_RECOMMENDED_PRODUCT',
  CART_REMOVE_PRODUCT = 'CART_REMOVE_PRODUCT',

  CART_OPEN = 'CART_OPEN',
  CART_CLOSE = 'CART_CLOSE',

  FORM_REQUEST_PRODUCT_OPEN = 'FORM_REQUEST_PRODUCT_OPEN',
  FORM_REQUEST_PRODUCT_ADD = 'FORM_REQUEST_PRODUCT_ADD',
  FORM_REQUEST_PRODUCT_REMOVE = 'FORM_REQUEST_PRODUCT_REMOVE',
  FORM_REQUEST_PRODUCT_INPUT = 'FORM_REQUEST_PRODUCT_INPUT',
  FORM_REQUEST_PRODUCT_SUBMIT = 'FORM_REQUEST_PRODUCT_SUBMIT',
  FORM_REQUEST_PRODUCT_CLOSE = 'FORM_REQUEST_PRODUCT_CLOSE',

  // contact-sales page
  FORM_CONTACT_SALES_INPUT = 'FORM_CONTACT_SALES_INPUT',
  FORM_CONTACT_SALES_PRODUCT_ADD = 'FORM_CONTACT_SALES_PRODUCT_ADD',
  FORM_CONTACT_SALES_PRODUCT_REMOVE = 'FORM_CONTACT_SALES_PRODUCT_REMOVE',
  FORM_CONTACT_SALES_SUBMIT = 'FORM_CONTACT_SALES_SUBMIT',
}
