import { inject, Injectable } from '@angular/core';
import { TProductEntity } from '@core/utils/products/entities/product.entity';
import { IProductPackageEntity } from '@core/utils/products/entities/product-package.entity';
import { IViewCategoryEntity } from '@core/utils/products/entities/product-view-category.entity';
import { mergeLocalizedData } from '@core/utils/products/merge-localized-data';
import { TranslateService } from '@noda-lib/translate';
import { RxStore } from '@noda-lib/utils/stores';
import { TranslateLang } from '@translate/constants/available-langs';
import { combineLatest, distinctUntilChanged, map, Observable, shareReplay, switchMap } from 'rxjs';

import { MARKETPLACE_STORE, MarketplaceStore } from '../marketplace.store';

type TLocalizeValues<T> = (lang: TranslateLang) => Observable<T[]>;

@Injectable({
  providedIn: 'root',
})
export class MarketplaceStoreFacade {
  private storeService: RxStore<MarketplaceStore> = inject(MARKETPLACE_STORE);
  private translateService: TranslateService = inject(TranslateService);

  public localizedProducts: Observable<TProductEntity[]> = this._selectLocalizedProducts();
  public localizedPackages: Observable<IProductPackageEntity[]> = this._selectLocalizedPackages();
  public localizedViewCategories: Observable<IViewCategoryEntity[]> =
    this._selectLocalizedViewCategories();

  public selectedProducts$: Observable<TProductEntity[]> = combineLatest([
    this.localizedProducts,
    this.storeService.select('selectedProductsIds'),
  ]).pipe(
    map(([products, ids]) =>
      products?.filter(({ externalId }) => ids.some(id => externalId === id)),
    ),
  );

  public recommendedProductsExceptSelected$: Observable<TProductEntity[]> = combineLatest([
    this.localizedProducts,
    this.storeService.select('selectedProductsIds'),
    this.storeService.select('recommendedProductsIds'),
  ]).pipe(
    map(([products, selectedProducts, recommendedProducts]) =>
      recommendedProducts
        ?.slice(0, 4)
        .filter(externalId => !selectedProducts.includes(externalId))
        .map(externalId => products?.find(product => product.externalId === externalId))
        .filter(Boolean),
    ),
  );

  public patchValue<K extends keyof MarketplaceStore, V extends Partial<MarketplaceStore[K]>>(
    key: K,
    value: V,
  ): void {
    const currentValue: MarketplaceStore[K] = this.storeService.getValue(key);
    this.storeService.setValue(key, { ...currentValue, ...value });
  }

  private selectProduct(product: TProductEntity): void {
    this.storeService.setValue('selectedProductsIds', [
      ...this.storeService.getValue('selectedProductsIds'),
      product.externalId,
    ]);
  }

  private unselectProduct(product: TProductEntity): void {
    this.storeService.setValue(
      'selectedProductsIds',
      this.storeService
        .getValue('selectedProductsIds')
        .filter(externalId => externalId !== product.externalId),
    );
  }

  public toggleProduct(product: TProductEntity): void {
    const selectedProductsIds: string[] = this.storeService.getValue('selectedProductsIds');
    const isSelected: boolean = selectedProductsIds.includes(product.externalId);

    if (isSelected) {
      this.unselectProduct(product);
    } else {
      this.selectProduct(product);
    }
  }

  public isProductSelected(externalId: string): boolean {
    const selectedProductsIds: string[] = this.storeService.getValue('selectedProductsIds');

    return selectedProductsIds.includes(externalId);
  }

  public isProductSelected$(externalId: string): Observable<boolean> {
    return this.storeService
      .select('selectedProductsIds')
      .pipe(map((selectedProductsIds: string[]) => selectedProductsIds.includes(externalId)));
  }

  public getLocalizedProducts(): TProductEntity[] {
    const lang: TranslateLang = this.translateService.getActiveLang() as TranslateLang;
    const storeValue: Partial<Record<TranslateLang, TProductEntity[]>> =
      this.storeService.getValue('products');

    return mergeLocalizedData(storeValue, lang, 'externalId');
  }

  private _selectLocalizedProducts(): Observable<TProductEntity[]> {
    const localizeValues: TLocalizeValues<TProductEntity> = (lang: TranslateLang) => {
      return this.storeService.select('products').pipe(
        distinctUntilChanged(),
        map(value => mergeLocalizedData(value, lang, 'externalId')),
      );
    };

    return (this.translateService.langChanges() as Observable<TranslateLang>).pipe(
      switchMap(localizeValues),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private _selectLocalizedPackages(): Observable<IProductPackageEntity[]> {
    const localizeValues: TLocalizeValues<IProductPackageEntity> = (lang: TranslateLang) => {
      return this.storeService.select('productPackages').pipe(
        distinctUntilChanged(),
        map(value => mergeLocalizedData(value, lang, 'type')),
      );
    };

    return (this.translateService.langChanges() as Observable<TranslateLang>).pipe(
      switchMap(localizeValues),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private _selectLocalizedViewCategories(): Observable<IViewCategoryEntity[]> {
    const localizeValues: TLocalizeValues<IViewCategoryEntity> = (lang: TranslateLang) => {
      return this.storeService.select('viewCategories').pipe(
        distinctUntilChanged(),
        map(value => mergeLocalizedData(value, lang, 'type')),
      );
    };

    return (this.translateService.langChanges() as Observable<TranslateLang>).pipe(
      switchMap(localizeValues),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
