import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { SelectorStylesDirective } from '@noda-lib/utils/directives';

import { ANShowShadow } from './animations/shadow.animations';

@Component({
  selector: 'noda-shadow',
  standalone: true,
  templateUrl: './shadow.component.html',
  styleUrls: ['./shadow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ANShowShadow],
  imports: [SelectorStylesDirective],
})
export class ShadowComponent {
  @Input('contentCenter')
  public contentCenter: boolean = true;
  @Input('disabled') public disabledClose: boolean = false;
  @Output('close')
  private _close: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('@ANShowShadow')
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private ANShowShadow: unknown;

  public close(e: MouseEvent): void {
    const isCanClose: boolean = (e.target as HTMLElement).hasAttribute('data-close-element');
    if (isCanClose && !this.disabledClose) {
      this._close.emit();
    }
  }
}
