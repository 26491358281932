export enum EnGAEventNames {
  HOME_ADD_PRODUCT = 'home_click_add_product',
  HOME_REMOVE_PRODUCT = 'home_click_remove_product',
  HOME_PRODUCT_READ_MORE = 'home_click_read_more',

  OPEN_FORM_REQUEST_PRODUCT = 'open_form_request_product',
  CLOSE_FORM_REQUEST_PRODUCT = 'close_form_request_product',

  CLICK_START_USING_NODA = 'click_start_using_noda',

  CLICK_CATALOG_APPLY_FILTER = 'click_catalog_apply_filter',
  CLICK_CATALOG_CATEGORY = 'click_catalog_category',

  CART_ADD_RECOMMENDED_PRODUCT = 'cart_click_add_recommended_product',
  CART_REMOVE_PRODUCT = 'cart_click_remove_product',

  CART_OPEN = 'cart_open',
  CART_CLOSE = 'cart_close',

  FORM_SUBMIT_CONTACT_SALES = 'form_submit_contact_sales',
  FORM_AUTOFILL_SUBMIT_CONTACT_SALES = 'form_autofill_submit_contact_sales',
  FORM_SUBMIT_REQUEST_PRODUCTS = 'form_submit_request_products',

  CONTACT_SALES_PAGE_OPEN = 'contact_sales_page_open',
}
