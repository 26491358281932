import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MarketplaceStoreFacade } from '@core/store/services/marketplace.store.facade';
import { TProductEntity } from '@core/utils/products/entities/product.entity';
import { Observable } from 'rxjs';

import { CartProductCardImports } from './imports/cart-product-card.imports';

@Component({
  selector: 'noda-cart-product-card',
  standalone: true,
  imports: CartProductCardImports,
  templateUrl: './cart-product-card.component.html',
  styleUrls: ['./cart-product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartProductCardComponent {
  public product: TProductEntity;
  public isProductSelected$: Observable<boolean>;
  @Input() public selected: boolean = false;
  @Input() public useSidebarStyles: boolean = false;
  @Input() public useRelatedStyles: boolean = false;
  @Input() public canBeRemoved: boolean = false;
  @Input() public actionType: 'add' | 'checked' | 'remove';

  @Output()
  public toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input('product')
  public set _product(value: TProductEntity) {
    this.product = value;
    this.isProductSelected$ = this.storeFacade.isProductSelected$(value.externalId);
  }

  constructor(private storeFacade: MarketplaceStoreFacade) {}

  public toggleToCart($event: Event): void {
    $event.preventDefault();

    const isProductSelected: boolean = this.storeFacade.isProductSelected(this.product.externalId);

    this.toggle.emit(isProductSelected);
  }
}
