import { InjectionToken, makeEnvironmentProviders, signal } from '@angular/core';
import { isEqualJSON, isNotNullish, selectObjectProps, recordObject, fillObject, assign, makeReadonlySignal } from '@noda-lib/utils/handlers';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, map, filter, take } from 'rxjs/operators';
function RxStoreToken(storeName) {
  return new InjectionToken(`STORE:${storeName}`);
}
class RxStore {
  constructor(StoreConstructor) {
    this.StoreConstructor = StoreConstructor;
    this.store$ = new BehaviorSubject(null);
    this.stateChanges = this.initStoreChangesSub();
    this.store$.next(new StoreConstructor());
  }
  initStoreChangesSub() {
    return this.store$.asObservable().pipe(distinctUntilChanged(this.isEqualValues));
  }
  isEqualValues(previous, current) {
    const isEqualValues = isEqualJSON(previous, current);
    return isEqualValues;
  }
  getStore() {
    return this.store$.getValue();
  }
  get DefaultStore() {
    return new this.StoreConstructor();
  }
  getValue(key) {
    return this.store$.getValue()[key];
  }
  listen() {
    return this.store$.asObservable();
  }
  select(key) {
    return this.store$.asObservable().pipe(map(store => store[key]), distinctUntilChanged(this.isEqualValues));
  }
  selectFirstDefinedValue(key, mode = 'default') {
    const stream$ = this.store$.asObservable().pipe(map(store => store[key]), filter(isNotNullish));
    const value = mode === 'default' ? stream$.pipe(take(1)) : firstValueFrom(stream$);
    return value;
  }
  /**
   * @deprecated use method patchStore
   */
  updateStore(value) {
    this.patchStore(value);
  }
  /**
   * @deprecated use method setValue
   */
  mergeStore(key, value) {
    this.setValue(key, value);
  }
  // * New renamed methods
  patchStore(partialStore) {
    const currentStore = this.getStore();
    const updatedStore = Object.assign({}, currentStore, partialStore);
    this.store$.next(updatedStore);
  }
  setValue(key, value) {
    this.patchStore({
      [key]: value
    });
  }
  reset(emitEvent = true) {
    const Store = this.getStore();
    Object.assign(Store, this.DefaultStore);
    if (emitEvent) this.patchStore(Store);
  }
  resetFields(fields, options) {
    const isNonNullable = options?.nonNullable;
    if (isNonNullable) {
      const resetObject = selectObjectProps(this.DefaultStore, fields);
      this.patchStore(resetObject);
    } else {
      const resetObject = recordObject(fields, null);
      this.patchStore(resetObject);
    }
  }
}

//  * Exports
const ROOT_RX_STORE = new InjectionToken('ROOT_STORE');

/* eslint-disable @typescript-eslint/naming-convention */
function provideRxRootStore(Store) {
  return makeEnvironmentProviders([{
    provide: ROOT_RX_STORE,
    useValue: new RxStore(Store)
  }]);
}
function provideRxChildStore(storeToken, Store) {
  return [{
    provide: storeToken,
    useValue: new RxStore(Store)
  }];
}
function SignalStoreToken(storeName) {
  return new InjectionToken(`SIGNAL-STORE:${storeName}`);
}
const DEFAULT_SELECT_SIGNAL_OPTIONS = {
  readonly: true
};
const DEFAULT_RESET_SIGNAL_OPTIONS = {
  nonNullable: true
};
function makeReadonlyStore(store) {
  const storeWithReadonlySignals = Object.entries(store).reduce((accum, [key, value]) => {
    accum[key] = value.asReadonly();
    return accum;
  }, {});
  return Object.freeze(storeWithReadonlySignals);
}

/* eslint-disable @typescript-eslint/prefer-reduce-type-parameter */
class SignalStore {
  constructor(StoreConstructor) {
    this.StoreConstructor = StoreConstructor;
    this.store = null;
    this.initStore();
  }
  initStore() {
    const instance = new this.StoreConstructor();
    const store = Object.entries(instance).reduce((accum, [key, value]) => {
      accum[key] = signal(value);
      return accum;
    }, {});
    this.store = Object.freeze(store);
  }
  getInstance(options) {
    const instance = options?.nonNullable ? new this.StoreConstructor() : fillObject(new this.StoreConstructor(), null);
    return instance;
  }
  getStore(type = 'readonly') {
    const store = type === 'writable' ? this.store : makeReadonlyStore(this.store);
    return store;
  }
  getValue(key) {
    return this.store[key]();
  }
  setValue(key, value) {
    this.store[key].set(value);
  }
  patchStore(store) {
    Object.entries(store).forEach(([key, value]) => this.setValue(key, value));
  }
  select(key, options) {
    const {
      readonly
    } = assign(DEFAULT_SELECT_SIGNAL_OPTIONS, options);
    return !readonly ? this.store[key] : makeReadonlySignal(this.store[key]);
  }
  reset(options) {
    const resetOptions = assign(DEFAULT_RESET_SIGNAL_OPTIONS, options);
    const instance = this.getInstance(resetOptions);
    Object.entries(instance).forEach(([key, value]) => this.setValue(key, value));
  }
  resetFields(fields, options) {
    const instance = this.getInstance(options);
    fields.forEach(field => this.setValue(field, instance[field]));
  }
}
class SomeState {
  constructor() {
    this.mode = null;
  }
}
const state = new SignalStore(SomeState);
// state.get('mode');

const ROOT_SIGNAL_STORE = new InjectionToken('ROOT_SIGNAL_STORE');
function provideRootSignalStore(store) {
  return makeEnvironmentProviders([{
    provide: ROOT_SIGNAL_STORE,
    useValue: new SignalStore(store)
  }]);
}
function provideChildSignalStore(storeToken, store) {
  return {
    provide: storeToken,
    useValue: new SignalStore(store)
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { ROOT_RX_STORE, ROOT_SIGNAL_STORE, RxStore, RxStoreToken, SignalStore, SignalStoreToken, provideChildSignalStore, provideRootSignalStore, provideRxChildStore, provideRxRootStore };
