import { ISimple } from '@noda-lib/utils/interfaces';
import { DEFAULT_LANG } from '@translate/constants/available-langs';

export function mergeLocalizedData<T>(value: ISimple<T[]>, lang: string, idKey: keyof T): T[] {
  if (lang === DEFAULT_LANG) {
    return value?.[DEFAULT_LANG];
  }

  const localizedValues: T[] = value?.[lang] || [];
  const defaultValues: T[] = value?.[DEFAULT_LANG] || [];
  const filteredValues: T[] = defaultValues.filter(
    item => !localizedValues.some(v => v[idKey] === item[idKey]),
  );

  return localizedValues.concat(filteredValues);
}
