import { Params } from '@angular/router';
import { ISelectCountryOption } from '@core/interfaces/form.interface';
import { TCounterItemsByCategory } from '@core/types/category-item-counter.type';
import { TProductEntity } from '@core/utils/products/entities/product.entity';
import { IProductLabelEntity } from '@core/utils/products/entities/product-label.entity';
import { IProductPackageEntity } from '@core/utils/products/entities/product-package.entity';
import { IViewCategoryEntity } from '@core/utils/products/entities/product-view-category.entity';
import { RxStoreToken } from '@noda-lib/utils/stores';
import { TranslateLang } from '@translate/constants/available-langs';

type TPartialRecord<V> = Partial<Record<TranslateLang, V[]>>;

export class MarketplaceStore {
  public products: TPartialRecord<TProductEntity> = null;
  public productPackages: TPartialRecord<IProductPackageEntity> = null;
  public viewCategories: TPartialRecord<IViewCategoryEntity> = null;
  public labels: TPartialRecord<IProductLabelEntity> = null;

  public recommendedProductsIds: string[] = null;
  public countries: ISelectCountryOption[] = [];

  public selectedProductsIds: string[] = [];
  public categoryProductCount: TCounterItemsByCategory = {};
  public selectedCategoryProductCount: TCounterItemsByCategory = {};
  public urlQuery: Params = {};
}

export const MARKETPLACE_STORE = RxStoreToken<MarketplaceStore>('MARKETPLACE');
