<div
  ngSelector="body"
  class="shadow"
  [ngSelectorStyles]="{ overflow: 'hidden' }"
>
  <div
    data-close-element
    class="shadow-content"
    [class.center]="contentCenter"
    (mousedown)="close($event)"
  >
    <ng-content />
  </div>
</div>
